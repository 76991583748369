import React, { useEffect, useState } from 'react';
import AOS from "aos";
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import { useTranslation } from "react-i18next";
import { getProducts } from "../api/axios";
import CarBrandsReference from '../components/CarBrandsReference';
import PartnersReference from '../components/PartnersReference';

const Store = () => {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    document.title = 'Asferrari ChipTuning';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getProducts()
      .then(value => setProducts(value?.data?.data))
  }, []);

  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }

  return (
    <>
      <OffCanvasTuning />

      <main>
        <div className="road">
          <div className="container-fluid position-relative">
            <ul className="road__list">
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link active" href={"#intro"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#store"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#references"}></a></li>
            </ul>
          </div>
        </div>
        <section id="intro" className="intro">
          <img src="/assets/images/store-bg.png" alt="background" className="intro__background" />
          <div data-aos="fade-right" className="container h-100">
            <div className="intro__content">
              <h1 className="intro__headline">{t("join-club-today")}</h1>
              <p className="intro__description">{t("where-speed-meets-sub")}</p>
              <a href="#" className="intro__link" data-bs-toggle="offcanvas" data-bs-target="#tuneCarMenu" aria-controls="tuneCarMenu">
                <span>{t("lets-tune-your-car")}</span>
                <div className="intro__link-icon">
                  <img src="/assets/icons/right.svg" alt="right icon" />
                </div>
              </a>
            </div>
          </div>
        </section>

        <section id="store" className="store">
          <div data-aos="fade-left" className="container">
            <div className="store__wrapper row g-4">
              {
                products?.map((value, index) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-4">
                      <a href="#">
                        <div className="card__primary">
                          <div className="card__primary-img">
                            <img src={"data:image/png;base64, " + value?.image} alt={value?.title} />
                          </div>
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <span className="card__primary-title">{value?.title}</span>
                            <span className="card__primary-price">{value?.price}</span>
                          </div>
                          <p className="card__primary-description">{value?.description}</p>
                          <a type="button"
                            href={value?.link}
                            className="btn btn-dark card__primary-btn">
                            {t('buy-now')}
                            <img src="/assets/icons/right.svg" height="16" alt="right" />
                          </a>
                        </div>
                      </a>
                    </div>
                  )
                })
              }

            </div>
          </div>
        </section>
        <CarBrandsReference />
        <PartnersReference />
      </main>
    </>
  )
}
export default Store