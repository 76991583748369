import React, {useEffect, useState} from 'react';
import AOS from "aos";
import {getTuningByAllData} from "../api/axios";
import OffCanvasTuning from "../components/OffCanvasTuning";
import {useTranslation} from "react-i18next";

import {useParams} from "react-router-dom";

const Tuning = () => {
  const {t, i18n} = useTranslation();
  const { brandName, modelName, year, engineName } = useParams();
  console.log(brandName, modelName, year, engineName, "OOO")

  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = 'Asferrari ChipTuning';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getTuningByAllData(brandName, modelName, year, engineName).then(value => {
      setData(value?.data)
    });
    
  }, []);



  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }

  return (
    <>
      <OffCanvasTuning/>

      <main>
        <div className="road">
          <div className="container-fluid position-relative">
            <ul className="road__list">
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link active" href={"#tuning"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#cars"}></a></li>
            </ul>
          </div>
        </div>
        <section id="tuning" data-aos="fade-right" className="tuning">
          <div className="container">
            <div className="row g-4">
              <div className="col-6 d-none d-sm-block">
                <div className="tuning__brand">
                  <img src="/assets/tuning.svg" alt="audi banner"/>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="tuning__content">
                  <h1 className="tuning__headline">{data?.["Make"]} {data?.["Model"]} {data?.["Engine"]}</h1>
                  <p className="tuning__description">
                    {t('tuning-audi-details')}
                  </p>
                  <span className="tuning__subtitle">{t('horse-power')}</span>
                  <div className="tuning__bar">
                    <div className="tuning__bar-standart" style={{width: "30%"}}></div>
                    <div className="tuning__bar-stage" style={{width: "15%"}}></div>
                    <span className="tuning__bar-value">+{data?.["BHP difference"]}</span>
                  </div>
                  <div className="tuning__data d-flex align-items-center justify-content-between">
                    <div className="tuning__data-item">
                      {t('factory')} <span>{data?.["BHP standard"]}</span>
                    </div>
                    <div className="tuning__data-item">
                      {t('asferrari-tuned')} <span>{data?.["BHP tuned"]}</span>
                    </div>
                  </div>
                  <span className="tuning__subtitle">{t('torque')}</span>
                  <div className="tuning__bar">
                    <div className="tuning__bar-standart" style={{width: "30%"}}></div>
                    <div className="tuning__bar-stage" style={{width: "15%"}}></div>
                    <span className="tuning__bar-value">+{data?.["TORQUE difference"]}</span>
                  </div>
                  <div className="tuning__data d-flex align-items-center justify-content-between">
                    <div className="tuning__data-item">
                      {t('factory')} <span>{data?.["TORQUE standard"]}</span>
                    </div>
                    <div className="tuning__data-item">
                      {t('asferrari-tuned')} <span>{data?.["TORQUE tuned"]}</span>
                    </div>
                  </div>
                  <div className="d-flex mt-2 gap-3 align-items-center justify-content-end flex-wrap">
                    <a target="_blank" href="https://www.paypal.com/ncp/payment/88Z8RR5BMVWXU">
                      <button type="button" className="btn btn-paypal">
                        {t("buy-with")}
                        <img width="60" height="14.5" src="/assets/icons/paypal.svg" alt="paypal"/>
                        <img width="8" height="16" src="/assets/icons/right.svg" alt="right"/>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="cars" data-aos="fade-left" className="cars">
          <div className="container">
            <h2 className="title__center">
              {t("similar-cars")} <br/>
              <span>{t("with-asferrari-tuned")}</span>
            </h2>
            <div className="cars__wrapper row g-4">
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#">
                  <div className="card__secondary">
                    <div className="card__secondary-img">
                      <img src="/assets/images/cars-1.png" alt="car picture"/>
                    </div>
                    <a href="#" className="card__secondary-link">
                      <span>Check more for instagram</span>
                      <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#">
                  <div className="card__secondary">
                    <div className="card__secondary-img">
                      <img src="/assets/images/cars-2.png" alt="car picture"/>
                    </div>
                    <a href="#" className="card__secondary-link">
                      <span>Check more for instagram</span>
                      <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#">
                  <div className="card__secondary">
                    <div className="card__secondary-img">
                      <img src="/assets/images/cars-3.png" alt="car picture"/>
                    </div>
                    <a href="#" className="card__secondary-link">
                      <span>Check more for instagram</span>
                      <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#">
                  <div className="card__secondary">
                    <div className="card__secondary-img">
                      <img src="/assets/images/cars-4.png" alt="car picture"/>
                    </div>
                    <a href="#" className="card__secondary-link">
                      <span>Check more for instagram</span>
                      <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#">
                  <div className="card__secondary">
                    <div className="card__secondary-img">
                      <img src="/assets/images/cars-5.png" alt="car picture"/>
                    </div>
                    <a href="#" className="card__secondary-link">
                      <span>Check more for instagram</span>
                      <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#">
                  <div className="card__secondary">
                    <div className="card__secondary-img">
                      <img src="/assets/images/cars-6.png" alt="car picture"/>
                    </div>
                    <a href="#" className="card__secondary-link">
                      <span>Check more for instagram</span>
                      <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#">
                  <div className="card__secondary">
                    <div className="card__secondary-img">
                      <img src="/assets/images/cars-1.png" alt="car picture"/>
                    </div>
                    <a href="#" className="card__secondary-link">
                      <span>Check more for instagram</span>
                      <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#">
                  <div className="card__secondary">
                    <div className="card__secondary-img">
                      <img src="/assets/images/cars-2.png" alt="car picture"/>
                    </div>
                    <a href="#" className="card__secondary-link">
                      <span>Check more for instagram</span>
                      <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#">
                  <div className="card__secondary">
                    <div className="card__secondary-img">
                      <img src="/assets/images/cars-3.png" alt="car picture"/>
                    </div>
                    <a href="#" className="card__secondary-link">
                      <span>Check more for instagram</span>
                      <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                    </a>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section> */}

      </main>
    </>
  )
}
export default Tuning