import React, { useEffect, useState } from 'react';
import AOS from "aos";
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import { useTranslation } from "react-i18next";
import { getAcademyById } from "../api/axios";
import { useParams } from "react-router-dom";
import CarBrandsReference from '../components/CarBrandsReference';
import PartnersReference from '../components/PartnersReference';

const AcademyDetail = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [academyDetail, setAcademyDetail] = useState([]);

  useEffect(() => {
    document.title = 'Asferrari ChipTuning';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getAcademyById(id).then(value => setAcademyDetail(value?.data?.data))
  }, []);
  return (
    <>
      <OffCanvasTuning />

      <main>
        <section className="intro">
          <img src={"data:image/png;base64, " + academyDetail?.academyPicture} alt="background" className="intro__background" />
          <div data-aos="fade-right" className="container">
            <div className="intro__content">
              <h1 className="intro__headline">{academyDetail?.title}</h1>
            </div>
          </div>
        </section>

        <section className="about">
          <div data-aos="fade-left" className="container">
            <div className="about__content">
              <h2 className="about__headline">{academyDetail?.title}</h2>
              <p className="about__description" dangerouslySetInnerHTML={{ __html: academyDetail?.text }}>
              </p>
            </div>
          </div>
        </section>
        <CarBrandsReference />
        <PartnersReference />
      </main>
    </>
  )
}
export default AcademyDetail