import React, {useEffect, useState} from 'react';
import {getMakes, getModelsByBrandName, getTuningByAllData, getYearsByBrandName, getModelsByMake} from "../api/axios";
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';

const OffCanvasTuning = () => {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [brandName, setBrandName] = useState("");
  const [selectedModel, setSelectedModel] = useState({});
  const [modelName, setModelName] = useState("");

  const [motorOptions, setEngineOptions] = useState([]);
  const [engineName, setEngineName] = useState("");

  const [yearOptions, setYearOptions] = useState([]);
  const [year, setYear] = useState("");

  const [tuningData, setTuningData] = useState("");

  function getYearsByBrandAndModel(model) {
    getYearsByBrandName(brandName, model)
      .then(value => {
        setYearOptions(value?.data)
      })
  }

  function getModelsByMakeName(make) {
    getModelsByMake(make)
      .then(value => {
        console.log(value);
        setSelectedBrand(value?.data)
      })
  }

  function getMotorsByBrandAndModelAndYear(year) {
    getModelsByBrandName(brandName, modelName, year)
      .then(value => {
        setEngineOptions(value?.data)
      })
  }

  useEffect(() => {
    setBrandName(t('select-car'))
    setModelName(t('model'))
    setEngineName(t('engine'))
    setYear(t('year'))

    getMakes().then(value => setBrands(value?.data))
  }, []);

  function getTuning(engineName) {
    getTuningByAllData(brandName, modelName, year, engineName)
      .then(value => {
        setTuningData(value?.data)
        console.log(value?.data)
      })
  }

  function goToTuning(e) {
    if (brandName !== "" && modelName != "" & year !== "" & engineName !== "") {
      //navigate('/tuning',{state: {brandName, modelName, year, engineName}});
      window.location.href = `/tuning/${encodeURIComponent(brandName)}/${encodeURIComponent(modelName)}/${encodeURIComponent(year)}/${encodeURIComponent(engineName)}`;
    }
  }

  return (
    <>
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="tuneCarMenu" aria-labelledby="tuneCarMenu">
        <div className="offcanvas-header">
          <a className="navbar-brand" href="/">
            <img src="/assets/images/logo.png" alt="AsFerrari tuning" width={"200"}/>
          </a>

          <button type="button" className="btn btn-nav-close" data-bs-dismiss="offcanvas" aria-label="Close">
            <span>Close</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path
                d="M17.6513 15.0002L29.4509 3.20195C30.1833 2.4695 30.1833 1.28197 29.4509 0.549582C28.7184 -0.182868 27.5309 -0.182868 26.7985 0.549582L15.0002 12.3491L3.20195 0.549582C2.4695 -0.182868 1.28197 -0.182868 0.549582 0.549582C-0.18281 1.28203 -0.182868 2.46956 0.549582 3.20195L12.3491 15.0002L0.549582 26.7985C-0.182868 27.531 -0.182868 28.7185 0.549582 29.4509C1.28203 30.1833 2.46956 30.1833 3.20195 29.4509L15.0002 17.6513L26.7984 29.4509C27.5309 30.1833 28.7184 30.1833 29.4508 29.4509C30.1833 28.7184 30.1833 27.5309 29.4508 26.7985L17.6513 15.0002Z"
                fill="white"/>
            </svg>
          </button>
        </div>
        <div className="offcanvas-body">
          <div data-aos="fade-right" className="filter mt-4">
            <div className="container">
              <div className="filter__wrapper-sm">
                <div className="dropdown">
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">{brandName}</span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon"/>
                  </button>
                  <ul className="dropdown-menu select__menu">
                    {
                      brands.map((value, index) => {
                        return (
                          <li key={index}>
                            <button className="dropdown-item select__menu-item"
                                    type="button"
                                    onClick={e => {
                                      setBrandName(value);
                                      getModelsByMakeName(value);

                                      setModelName(t('model'))
                                      setEngineName(t('engine'))
                                      setYear(t('year'))
                                    }}>
                              {value}
                              <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                            </button>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="dropdown">
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">{modelName}</span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon"/>
                  </button>
                  <ul className="dropdown-menu select__menu">
                    {
                      selectedBrand?.map((value, index) => {
                        return (
                          <li key={index}>
                            <button className="dropdown-item select__menu-item"
                                    type="button"
                                    onClick={e => {
                                      setModelName(value);
                                      setSelectedModel(value);
                                      getYearsByBrandAndModel(value);
                                      setEngineName(t('engine'))
                                      setYear(t('year'))
                                    }}>
                              {value}
                              <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                            </button>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="dropdown">
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">{year}</span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon"/>
                  </button>
                  <ul className="dropdown-menu select__menu">
                    {
                      yearOptions?.map((value, index) => {
                        return (
                          <li key={index}>
                            <button className="dropdown-item select__menu-item"
                                    type="button"
                                    onClick={e => {
                                      setYear(value)
                                      getMotorsByBrandAndModelAndYear(value);
                                      setEngineName(t('engine'))
                                    }}>
                              {value}
                              <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                            </button>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="dropdown">
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">{engineName}</span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon"/>
                  </button>
                  <ul className="dropdown-menu select__menu">
                    {
                      motorOptions?.map((value, index) => {
                        return (
                          <li key={index}>
                            <button className="dropdown-item select__menu-item"
                                    type="button"
                                    onClick={e => {
                                      setEngineName(value);
                                      //getTuning(value);      
                                    }}>
                              {value}
                              <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon"/>
                            </button>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <button className="btn btn-danger" onClick={goToTuning}>
                  {t('lets-tune-your-car')}
                  <img src="/assets/icons/right.svg" alt="right"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OffCanvasTuning