import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";

const Contact = () => {
  const {t, i18n} = useTranslation();

  useEffect(() => {
    document.title = 'Asferrari ChipTuning';
  }, []);
  return (
    <>
      services
    </>
  )
}
export default Contact