import axios from 'axios';

const BASE_URL = 'https://www.asferrari.com/api/v1';;
const BASE_OBLAK_URL = 'https://asferrari.oblak.online/Api';


export const getTuning = (id) => {
  return axios.get(BASE_URL + `/tuning/get/${id}`, {});
}

// MAKES
export const getMakes = () => {
  return axios.get(BASE_OBLAK_URL + "/GetMakes", {});
}
// MODELS
export const getModelsByMake = (make) => {
  return axios.get(BASE_OBLAK_URL + `/GetModels?make=${make}`, {});
}


// YEARS
export const getYearsByBrandName = (make, model) => {
  return axios.get(BASE_OBLAK_URL + `/GetGenerations?make=${make}&model=${model}`, {});
}
export const getModelsByBrandName = (make, model, generation) => {
  return axios.get(BASE_OBLAK_URL + `/GetEngines?make=${make}&model=${model}&generation=${generation}`, {});
}

export const getTuningByAllData = (make, model, generation, engine) => {
  return axios.get(BASE_OBLAK_URL + `/GetCarDetails?make=${make}&model=${model}&generation=${generation}&engine=${engine}`, {});
}

export const getModelById = (brandId) => {
  return axios.get(BASE_URL + `/model/${brandId}`, {});
}

// WEB SETTINGS

export const getWebSettings = () => {
  return axios.get(BASE_URL + `/website/get`, {});
}

export const getServiceFAQs = () => {
  return axios.get(BASE_URL + `/servicefaq/list`, {});
}

export const getServices = () => {
  return axios.get(BASE_URL + `/service/list`, {});
}

export const getProducts = () => {
  return axios.get(BASE_URL + `/product/list`, {});
}

export const getTunings = (brand, model) => {
  return axios.get(BASE_URL + `/tuning/list/${brand}/${model}`, {});
}

export const getAcademyById = (id) => {

  return axios.get(BASE_URL + `/academy/get/${id}`, {});
}
export const getAcademyList = () => {

  return axios.get(BASE_URL + `/academy/list`, {});
}

export const getAbout = () => {

  return axios.get(BASE_URL + `/about/get`, {});
}

// DEALERS

export const getDealerList = () => {

  return axios.get(BASE_URL + `/dealer/list`, {});
}