import React, { useEffect, useState } from 'react';
import AOS from "aos";
import Swiper from 'swiper';
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import { useTranslation } from "react-i18next";
import { getServiceFAQs, getServices } from "../api/axios";
import CarBrandsReference from '../components/CarBrandsReference';
import PartnersReference from '../components/PartnersReference';

const Services = () => {
  const { t, i18n } = useTranslation();
  const [services, setServices] = useState([]);
  const [serviceFaqs, setServiceFaqs] = useState([]);

  useEffect(() => {
    document.title = 'Asferrari ChipTuning';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getServices().then(value => setServices(value?.data?.data))
    getServiceFAQs().then(value => setServiceFaqs(value?.data?.data))
  }, []);

  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }

  return (
    <>
      <OffCanvasTuning />

      <main>
        <div className="road">
          <div className="container-fluid position-relative">
            <ul className="road__list">
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link active" href={"#intro"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#store"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#faq"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#references"}></a></li>
            </ul>
          </div>
        </div>

        <section id="intro" className="intro">
          <img src="/assets/images/services-bg.png" alt="background" className="intro__background" />
          <div data-aos="fade-right" className="container h-100">
            <div className="intro__content">
              <h1 className="intro__headline">{t('services')}</h1>
              <p className="intro__description">{t('services-welcome')}</p>
              <a href="#store" className="intro__link">
                <span>{t('read-more')}</span>
                <div className="intro__link-icon">
                  <img src="/assets/icons/right.svg" alt="right icon" />
                </div>
              </a>
            </div>
          </div>
        </section>

        <section id="store" className="store">
          <div className="container">
            <div className="services__wrapper row g-4">
              {
                services.map((value, index) => {
                  return (
                    <div data-aos="fade-left" className="col-12 col-md-6 col-lg-4" key={index}>
                      <a href="#">
                        <div className="card__primary">
                          <div className="card__primary-img">
                            <img className={"service-image"} src={"data:image/png;base64, " + value?.servicePicture} alt={value?.title} />
                          </div>
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <span className="card__primary-title">{value?.title}</span>
                          </div>
                          <p className="card__primary-long-description">{value?.text}</p>
                        </div>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </section>

        <section id="faq" data-aos="fade-right" className="faq">
          <div className="container">
            <h2 className="faq__headline">{t('faq')}</h2>
            <div className="faq__wrapper">
              <div className="accordion accordion-flush">
                {
                  serviceFaqs.map((value, index) => {
                    return (
                      <div key={index} className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target={`#accordion${value?.id}`} aria-expanded="false" aria-controls={`accordion${value?.id}`}>
                            {value?.title}
                          </button>
                        </h2>
                        <div id={`accordion${value?.id}`} className="accordion-collapse collapse" data-bs-parent={`#accordion${value?.id}`}>
                          <div className="accordion-body">
                            {value?.text}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </div>
        </section>
        <CarBrandsReference />
        <PartnersReference />
      </main>
    </>
  )
}
export default Services