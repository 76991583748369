import './App.scss';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import './scss/style.scss';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Services from "./pages/Services";
import Academy from "./pages/Academy";
import AboutUs from "./pages/AboutUs";
import Store from "./pages/Store";
import Tuning from "./pages/Tuning";
import Contact from "./pages/Contact";
import 'bootstrap/dist/js/bootstrap.js';
import {useEffect, useState} from "react";
import {getWebSettings} from "./api/axios";
import AcademyDetail from "./pages/AcademyDetail";
import Dealers from './pages/Dealers';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  const [websiteData, setWebsiteData] = useState({});
  useEffect(() => {
    getWebSettings().then(value => setWebsiteData(value?.data?.data))
  }, [0]);
  return (
    <div className="App">
      <Header/>

      {/* routes start */}
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/academy" element={<Academy/>}/>
        <Route path="/academy-detail/:id" element={<AcademyDetail/>}/>
        <Route path="/about-us" element={<AboutUs/>}/>
        <Route path="/store" element={<Store/>}/>
        <Route path="/tuning/:brandName/:modelName/:year/:engineName" element={<Tuning/>}/>
        <Route path="/about-us" element={<Contact/>}/>
        <Route path="/dealers" element={<Dealers/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>

        {/* error page */}
        <Route path="*" element={<Home/>}/>
      </Routes>
      {/* routes end */}

      <Footer data={websiteData}/>
    </div>
  );
}

export default App;
