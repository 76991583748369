import React from 'react';
const TrustBox = () => {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div
            ref={ref}
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="56278e9abfbbba0bdcd568bc"
            data-businessunit-id="66a0ced4f462e383527b41d2"
            data-style-height="52px"
            data-style-width="100%"
            style={{ left: "-60px" }}
        >
            <a href="https://www.trustpilot.com/review/asferrari.com" target="_blank" rel="noopener" className='footer__menu-item'>Trustpilot</a>
        </div>
    );
};
export default TrustBox;