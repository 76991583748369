import React from 'react';
import { useTranslation } from "react-i18next";

const PartnersReference = () => {
  const { t, i18n } = useTranslation();

  return (
    <section id="references" className="references">
    <div data-aos="fade-right" className="container">
    <h2 class="reasons__headline">{t('partners')}</h2>
      <div className="swiper referencesSwiper">
        <div className="swiper-wrapper">
          <div className="swiper-slide references__item">
            <a href='https://www.magicmotorsport.com/'><img  className="partnerLogo" src={"/assets/images/magic.webp"} alt="magic"/></a>
          </div>
          <div className="swiper-slide references__item">
            <a href='https://www.hptuners.eu/'><img  className="partnerLogo" src={"/assets/images/HPT.webp"} alt="HPT"/></a>
          </div>
          <div className="swiper-slide references__item">
            <a href='http://dynomax-dyno.com/'><img  className="partnerLogo" src={"/assets/images/dynomax.png"} alt="dynomax"/></a>
          </div>
          <div className="swiper-slide references__item">
            <a href='https://www.dimsport.it/en/'><img className="partnerLogo" src={"/assets/images/dimsport.png"} alt="dimsport"/></a>
          </div>
          <div className="swiper-slide references__item">
            <a href='https://www.evc.de/en/default.asp'><img  className="partnerLogo" src={"/assets/images/evc.png"} alt="evc"/></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  )

}

export default PartnersReference