import React from 'react';
import "aos/dist/aos.css";
import 'swiper/css';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t, i18n } = useTranslation();

  const onChangeLanguage = (language) => {
    i18n.changeLanguage(language).then(value => window.location.reload())
  };

  return (
    <header>
      <nav className="navbar fixed-top navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="/assets/images/logo.svg" alt="AsFerrari tuning" width="200" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 30 22" fill="none">
              <rect opacity="0.3" y="18" width="30" height="4" rx="2" fill="white" />
              <rect opacity="0.3" y="9" width="30" height="4" rx="2" fill="white" />
              <rect opacity="0.3" width="30" height="4" rx="2" fill="white" />
            </svg>
          </button>
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <div className="offcanvas-logo">
                <img src="/assets/images/logo-mobile.svg" width="171 " alt="AsFerrari tuning" />
              </div>
              <button type="button" className="btn btn-nav-close" data-bs-dismiss="offcanvas" aria-label="Close">
                <span>Close</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <path
                    d="M17.6513 15.0002L29.4509 3.20195C30.1833 2.4695 30.1833 1.28197 29.4509 0.549582C28.7184 -0.182868 27.5309 -0.182868 26.7985 0.549582L15.0002 12.3491L3.20195 0.549582C2.4695 -0.182868 1.28197 -0.182868 0.549582 0.549582C-0.18281 1.28203 -0.182868 2.46956 0.549582 3.20195L12.3491 15.0002L0.549582 26.7985C-0.182868 27.531 -0.182868 28.7185 0.549582 29.4509C1.28203 30.1833 2.46956 30.1833 3.20195 29.4509L15.0002 17.6513L26.7984 29.4509C27.5309 30.1833 28.7184 30.1833 29.4508 29.4509C30.1833 28.7184 30.1833 27.5309 29.4508 26.7985L17.6513 15.0002Z"
                    fill="white" />
                </svg>
              </button>
            </div>
            <div className="offcanvas-body align-items-center">
              <ul className="navbar-nav justify-content-end flex-grow-1">
                <li className="nav-item">
                  <a className={`nav-link ${window.location.pathname === "/" ? 'active' : ''}`} href="/">
                    {t('home')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${window.location.pathname === "/services" ? 'active' : ''}`} href="/services">
                    {t('services')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${window.location.pathname === "/academy" ? 'active' : ''}`} href="/academy">
                    {t('academy')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${window.location.pathname === "/store" ? 'active' : ''}`} href="/store">
                    {t('store')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${window.location.pathname === "/about-us" ? 'active' : ''}`} href="/about-us">
                    {t('about-us')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${window.location.pathname === "/dealers" ? 'active' : ''}`} href="/dealers">
                    {t('dealers')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link`} href="http://asftuning.com/" >
                    {t('blog')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link`} href="https://files.asferrari.com/">
                    {t('log-in')}
                  </a>
                </li>
                <li className="nav-item">
                  <div className="nav-languages">
                    <button type="button"
                      className={`nav-languages-item ${i18n.language === 'cnr' ? 'active' : ''}`}
                      onClick={e => onChangeLanguage("cnr")}>ME
                    </button>
                    <div className="vertical-divider"></div>
                    <button type="button"
                      className={`nav-languages-item ${i18n.language === 'en' ? 'active' : ''}`}
                      onClick={e => onChangeLanguage("en")}>EN
                    </button>
                  </div>
                </li>
                <li className="nav-item">
                  <button type="btn" className="btn btn-dark" data-bs-toggle="offcanvas" data-bs-target="#tuneCarMenu"
                    aria-controls="tuneCarMenu">{t('tune-car')}
                  </button>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header