import React from 'react';
import { useTranslation } from "react-i18next";

const CarBrandsReference = () => {
  const { t, i18n } = useTranslation();

  return (
    <section id="references" className="references">
    <div data-aos="fade-right" className="container">
      <div className="swiper referencesSwiper">
        <div className="swiper-wrapper">
          <div className="swiper-slide references__item">
            <img src={"/assets/images/ferrari.png"} alt="ferrari logo"/>
          </div>
          <div className="swiper-slide references__item">
            <img src={"/assets/images/tesla.png"} alt="tesla logo"/>
          </div>
          <div className="swiper-slide references__item">
            <img src={"/assets/images/maserati.png"} alt="maserati logo"/>
          </div>
          <div className="swiper-slide references__item">
            <img src={"/assets/images/bugatti.png"} alt="bugatti logo"/>
          </div>
          <div className="swiper-slide references__item">
            <img src={"/assets/images/lamborghini-alt.png"} alt="lamborghini logo"/>
          </div>
          <div className="swiper-slide references__item">
            <img src={"/assets/images/land-rover.png"} alt="land rover logo"/>
          </div>
          <div className="swiper-slide references__item">
            <img src={"/assets/images/bmw.png"} alt="bmw logo"/>
          </div>
          <div className="swiper-slide references__item">
            <img src={"/assets/images/audi.png"} alt="audi logo"/>
          </div>
          <div className="swiper-slide references__item">
            <img src={"/assets/images/mercedes-benz-alt.png"} alt="mercedes benz logo"/>
          </div>
        </div>
      </div>
    </div>
  </section>
  )

}

export default CarBrandsReference